<template>
  <b-container>
    <b-row class="mb-4 pt-5 d-flex justify-content-between px-3">
      <curva-title class="mb-2" :title="$t('categories.catProducts')"></curva-title>
      <div class="d-flex gap_1">
        <dropdown-btn @select="getSelected"/>
        <router-link :to="{name: 'suggestProduct'}">
          <b-button class="curva-transparent-btn iq-border-radius-10">
            {{ $t('search.suggest') }}</b-button>
        </router-link>
        <router-link :to="{name: 'findProduct'}">
          <b-button class="curva-transparent-btn iq-border-radius-10">
            {{ $t('search.findProduct') }}</b-button>
        </router-link>
      </div>
    </b-row>
    <b-row>
      <b-col lg="3">
        <b-card class="side-filter mb-5">
          <b-row>
            <b-col :class="{'active-all': ($route.params.category === 'all-categories')}">
                <h4 class="py-3 header-cat cursor-pointer" @click="selectedMainCategory = null; currentPage = 1;
              $router.push({params: {category:'all-categories'}, query:{...$route.query, sub : false}})">
                <i class="text-warning las la-icons"></i> {{ $t('categories.allCats') }}
              </h4>
            </b-col>
          </b-row>
          <b-row class="list-row">
            <b-col class="list-col">
              <b-list-group id="filter-list">
                <span v-for="(category, ind) in categories" :key="ind" class="accordion-group">
<!--                  @click="$router.push(`/categories/${category.id}/?brand=${$route.query.brand || ''}&club=${$route.query.club|| ''}`)"-->
                  <b-list-group-item class="category-list category-list-main cursor-pointer"
                                     @click="changeSelectParent(category)"
                                     :aria-controls="'category-'+ ind"
                                     :class="{'not-collapsed': (category.id == $route.params.category)}"
                  >
<!--                    :class="{'not-collapsed': (category.id == $route.params.category)}"-->
                    <h4>{{ category.name }}</h4>
                  </b-list-group-item>
                  <!--                  :visible="category.id == selectedMainCategory"-->
                  <b-collapse :id="'category-'+ind"
                              :visible="category.id == selectedMainCategory"
                              accordion="my-accordion"
                  >
                    <b-list-group>
                      <b-list-group-item class="category-list category-list-sub cursor-pointer"
                                         :class="{'active': (sub.id == $route.query.sub)}"
                                         v-for="(sub, key) in category.sub_category" :key="key"
                                         @click="currentPage = 1;$router.push({query:{...$route.query,sub:sub.id}})">
                        {{ sub.subcat }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </span>
              </b-list-group>
            </b-col>
          </b-row>
          <div v-if="categories.length > 0" class="px-1">
            <p for="price-range" class="pt-4 mb-5 text-initial">{{ $t('filter.range') }}</p>
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(getByPrice)">
                <vue-range-slider v-if="windowWidth > 900" id="price-range" v-model="priceValue"
                                  :min="range.min" :max="range.max" :dotSize="20"
                                  :tooltipDir="tooltipDir" :formatter="formatter" :step="10" class="pb-5 pt-4 mb-3">
                </vue-range-slider>
                <div v-else class="mb-4 d-flex justify-content-between align-items-center gap_2">
                  <div class="d-inline-flex align-items-center gap_1 flex-grow-1">
                    <label for="min">{{ $t('filter.min') }}</label>
                    <b-form-input id="min" :min="range.min" :max="range.max" :validate="'number'"
                                  v-model="priceValue[0]" @focus="hideIndicator(true)"
                                  @blur="hideIndicator(false)"></b-form-input>
                  </div>
                  <div class="d-inline-flex align-items-center gap_1 flex-grow-1">
                    <label for="max">{{ $t('filter.max') }}</label>
                    <b-form-input id="max" :min="range.min" :max="range.max" :validate="'number'"
                                  v-model="priceValue[1]" @focus="hideIndicator(true)"
                                  @blur="hideIndicator(false)"></b-form-input>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn curva-granola-btn p-0 pr-4 pl-4">{{ $t('filter.apply') }}</button>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </b-card>
      </b-col>
      <b-col lg="9">
        <div id="offers-list" class="mb-5" v-if="!loadingProducts">
          <b-row v-if="products.length > 0">
            <b-col lg="4" md="6" cols="12" v-for="(product, key) in products" :key="key">
              <product-card :product="product" />
            </b-col>
          </b-row>
          <p v-else class="text-center font-size-22"> {{ $t('empty') }}</p>
        </div>
        <b-row class="mb-5 text-center p-5" v-else>
          <b-col><b-spinner variant="warning" type="grow" label="Spinning"></b-spinner></b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="d-flex justify-content-center">
        <b-pagination
            class="curva-pagination"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @input="priceFlag=true;getAllCat()"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import DropdownBtn from '@/components/core/dropdown/dropdown-btn'
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'
import CategoriesServices from '@/modules/categories/services/categories.services'
import EventBus from '@/eventBus'
export default {
  components: { DropdownBtn, VueRangeSlider },
  data () {
    return {
      categories: [],
      loadingProducts: true,
      products: [],
      sortValue: 'id&dir=desc',
      perPage: 0,
      currentPage: 1,
      rows: 1,
      selectedMainCategory: 0,
      selectedCategory: 0,
      range: { min: 0, max: 5000 },
      priceValue: [0, 5000],
      priceFlag: false,
      tooltipDir: ['bottom', 'top'],
      formatter: '',
      selectedTypeCategory: 'main',
      brand: this.$route.query.brand ? this.$route.query.brand : '',
      club: this.$route.query.club ? this.$route.query.club : '',
      windowWidth: ''
    }
  },
  methods: {
    getAllCat () {
      window.scrollTo(0, 0)
      this.loadingProducts = true
      const filterObj = {
        category: this.$route.query.sub ? this.$route.query.sub : this.$route.params.category,
        page: this.currentPage,
        min: this.priceFlag ? this.priceValue[0] : this.range.min,
        max: this.priceFlag ? this.priceValue[1] : this.range.max,
        type: this.$route.query.sub ? 'child' : 'main',
        brand: this.$route.query.brand ? this.$route.query.brand : null,
        club: this.$route.query.club ? this.$route.query.club : null,
        season: this.$route.query.season ? this.$route.query.season : null,
        sort: this.sortValue.substring(0, this.sortValue.indexOf('&dir')),
        dir: this.sortValue.substring(this.sortValue.indexOf('&dir') + 5, this.sortValue.length)
      }
      CategoriesServices.getCatProduct(filterObj).then(res => {
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.currentPage = res.data.meta.current_page
        this.products = res.data.data
        this.priceFlag = false
      }).finally(() => {
        this.loadingProducts = false
      })
    },
    check (id) {
      return this.$route.params.category === id
    },
    getCategories () {
      CategoriesServices.getCategories().then(res => {
        this.categories = res.data.data
      })
    },
    // getParentData (id) {
    //   this.loadingProducts = true
    //   this.selectedCategory = id
    //   let min = 0
    //   let max = 0
    //   if (this.priceFlag) {
    //     min = this.priceValue[0]
    //     max = this.priceValue[1]
    //   } else {
    //     min = this.range.min
    //     max = this.range.max
    //     this.priceFlag = true
    //   }
    //   CategoriesServices.getCategoryProducts(id, this.sortValue, this.currentPage, 'main', this.brand,
    //     this.club, min, max)
    //     .then(res => {
    //       this.rows = res.data.meta.total
    //       this.perPage = res.data.meta.per_page
    //       this.currentPage = res.data.meta.current_page
    //       this.products = res.data.data
    //     }).finally(() => {
    //       this.loadingProducts = false
    //     })
    // },
    // getData () {
    //   window.scrollTo(0, 0)
    //   this.loadingProducts = true
    //   let min = 0
    //   let max = 0
    //   if (this.priceFlag) {
    //     min = this.priceValue[0]
    //     max = this.priceValue[1]
    //   } else {
    //     min = this.range.min
    //     max = this.range.max
    //     this.priceFlag = true
    //   }
    //   if (this.selectedTypeCategory === 'child') {
    //     this.$router.push({ query: { ...this.$route.query, sub: this.selectedCategory } })
    //   }
    //   CategoriesServices.getCategoryProducts(this.selectedCategory, this.sortValue, this.currentPage, this.selectedTypeCategory, this.brand,
    //     this.club, min, max)
    //     .then(res => {
    //       this.rows = res.data.meta.total
    //       this.perPage = res.data.meta.per_page
    //       this.currentPage = res.data.meta.current_page
    //       this.products = res.data.data
    //     }).finally(() => {
    //       this.loadingProducts = false
    //     })
    // },
    getByPrice () {
      this.priceFlag = true
      this.getAllCat()
    },
    getSelected (value = 'id&dir=desc') {
      this.currentPage = 1
      this.sortValue = value
      this.getAllCat()
    },
    hideIndicator (flag) {
      EventBus.$emit('hide-indicator', flag)
    },
    changeSelectParent (category) {
      this.selectedMainCategory = category.id
      this.$router.push({
        params: { category: category.id },
        query: { ...this.$route.query, sub: false }
      })
    }
  },
  watch: {
    '$route' () {
      this.selectedMainCategory = this.$route.params.category
      this.getAllCat()
    }
    // '$route.params' (newId) {
    //   let type = 'main'
    //   if (this.$route.query.sub) {
    //     type = 'child'
    //   }
    //   this.loadingProducts = true
    //   this.selectedCategory = newId.category
    //   this.selectedTypeCategory = type
    //   CategoriesServices.getCategoryProducts(newId.category, this.sortValue, 1, type, this.brand, this.club)
    //     .then(res => {
    //       this.selectedMainCategory = newId.category
    //       this.rows = res.data.meta.total
    //       this.perPage = res.data.meta.per_page
    //       this.currentPage = res.data.meta.current_page
    //       this.products = res.data.data
    //     }).finally(() => {
    //       this.loadingProducts = false
    //     })
    // }
  },
  created () {
    VueRangeSlider.methods.handleKeyup = () => console.log('Slider')
    VueRangeSlider.methods.handleKeydown = () => console.log('Slider')
    this.getAllCat()
    this.selectedMainCategory = this.$route.params.category
    this.getCategories()
    // if (this.$route.query.sub) {
    //   this.selectedTypeCategory = 'child'
    //   this.selectedCategory = this.$route.query.sub
    //   this.getData()
    // } else {
    //   this.getParentData(this.selectedMainCategory)
    // }
    this.formatter = value => `${value} EGP`
  },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    core.endLoader()
  }
}
</script>
